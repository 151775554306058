body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  //font-family: $heading-font-family;
  font-size: em(28);
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

h1, h2, h3, h4, h5 {
  color: $color-font;
  a {
    color: $color-font;
  }
}

a {
  color: $color-font;
}

// admin menu
#admin-menu ul a {
  color: white;
}

p {
  margin: 0 0 $small-spacing;
}

a {
  //color: $action-color;
  //text-decoration: none;
  //transition: color $base-duration $base-timing;

&:active,
&:focus,
&:hover {
    color: shade($action-color, 25%);
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}


ul.tabs {
  list-style: none;
  li {
    display: inline-block;
    padding: .5em;
    margin: .5em .1em;
    border: 1px solid #ccc;
  }
}
