// Typography
// after node-bourbon update to version 5x of Borubon, we should switch to $font-stack-system
//$base-font-family: $font-stack-system;

$base-font-family: "Arial",sans-serif;
//$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

/*
* Ms Tedrik colors
*/
$color-font: #559098;
$color-font-dark: #3c656a;
$color-main-dark: #c6e7ec;
$color-main-light: #dbf0f5;
$color-background: #f5fbfb;

// Colors
$blue: #1565c0;
$black: #333;
$gray: #999;
$gray-light-2: #f3f4f4;
$light-gray: #ddd;
$dark-block: #132229;
$brown: #610301; // 97, 3. 1
$text: #304854;
$hp-navigace: #9ab3bf;
$hover-red: #a9110d;

//Background colors
$granatove-ruda: $brown;
$granatove-ruda: #670301;
$bezova: #D8C9B2;
$malinova-ruzova: #E03548;
$lipova-zelen: #C4DF9C;
$zluta: #FFEE78;
$tyrkysova: #009FB5;
$matova-peprna: #CEEDE8;
$cernomodra: #132229;

// Font Colors
$base-font-color: $black;
$action-color: $blue;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

//Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;
