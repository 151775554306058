ul.primary {
  margin: 50px 0 50px 30px;
  padding: 0;
  &.tabs {
    padding: 0;
    border: none;
    line-height: auto;
    height: auto;
    li {
      padding: 0;
      margin: 0;
      border: 0;
      a {
        padding: 10px 15px;
        background-color: $color-main-light;
        border: none;
        font-size: 14px;
        &.active {
          background-color: $color-font-dark;
          color: white !important;
        }
        &:hover {
          background-color: $color-font;
          color: white !important;
        }
      }
    }
  }
}

#user-login {
  margin-top: 30px;
  width: 70%;
}

@media (max-width: 470px) {
  .not-logged-in.page-user {
    .tabs .primary {
      display: flex;
      flex-direction: column;
      li {
        margin: 15px 0;
      }
    }
  }
}

.feed-icon {
  margin-left: 30px;
}

.item-list {
  .pager {
    margin-top: 50px;
  }
}
