/////////////////////////////////////////////////

@-ms-viewport { 
  width: device-width; 
}

img, iframe, canvas, video, svg {
    max-width: 100%;
    height: auto;
}


#page-wrapper{
  @include outer-container;
  //width: 1100px;
  max-width: 970px;
}


/*
* Container
*/

#container,
#footer-wrapper,
#header {
  @include outer-container;
}

#container {
  margin-bottom: 100px;
}


/*
* Main
*/
#main {
  @include span-columns(8);
  float: right;
  width: 69%;
  //width: 65%;
  padding-top: 40px;
}

.front {
  #main {
    padding-top: 0;
  }
}

/*
* sidebar
*/
#sidebar-left {
  @include span-columns(4);
  width: 30%;
  //width: 34%;
  margin-right: 0;
}























