.element-invisible {
  @include hide-visually;
}

/***************
 * Media
***************/
figure {
  margin: 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
  height: auto;
}

/***************
 * Lists
***************/
ul,
ol {
  //list-style-type: none;
  margin: 0;
  padding: 0;
}

dl {
  margin: 0;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}


/***************
 * Tables
***************/
table {
  border-collapse: collapse;
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;
}

th {
  border-bottom: 1px solid shade($base-border-color, 25%);
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: left;
}

td {
  border-bottom: $base-border;
  padding: $small-spacing 0;
}

tr,
td,
th {
  vertical-align: middle;
}


/***************
 * Buttons
***************/
#{$all-buttons} {
  appearance: none;
  background-color: $action-color;
  border: 0;
  border-radius: $base-border-radius;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: $small-spacing $base-spacing;
  text-align: center;
  text-decoration: none;
  transition: background-color $base-duration $base-timing;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: shade($action-color, 20%);
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: $action-color;
    }
  }
}

/***************
 * Forms
***************/
form {
  margin-left: 30px;
  margin-right: 30px;
}

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;
  padding: 0;
}

label {
  display: block;
  font-weight: 600;
  font-size: 17px;
  color: $color-font;
  margin-bottom: $small-spacing / 2;
  span {
    color: $color-font;
  }
}

.description {
  color: $color-font;
}

input,
select,
textarea {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

#{$all-text-inputs} {
  appearance: none;
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  margin-bottom: $small-spacing;
  padding: $base-spacing / 3;
  transition: border-color $base-duration $base-timing;
  width: 100%;

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:focus {
    border-color: $action-color;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }

  &:disabled {
    background-color: shade($base-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      border: $base-border;
    }
  }

  &::placeholder {
    color: tint($base-font-color, 40%);
  }
}

textarea {
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $small-spacing;
  width: 100%;
}

input[type=submit] {
  background-color: $color-font;
  color: white;
  &:hover {
    background-color: $color-font-dark;
  }
}
