/*
 * @file
 * This file is a custom file that loads all files. Each non-base layer
 * can be disabled.
 */

// Make sure the charset is set appropriately
@charset "UTF-8";

/*@import "normalize";  // use reboot.scss instead when launched and mature*/

@import "init/bourbon-init";
@import "init/neat-init";
//@import "init/susy-init";

// custom mixins and functions
@import "mixins-functions-extensions";

@import "variables";
@import "partials";
@import "layout";
// @import "partials"; use for region specific etc. but for small project just layout.scss is imho enough
@import "typography";
@import "elements";

//@import "components/COMPONENTNAME"; // for REUSABLE components (BEM syntaxed etc.)

body {
  padding: 20% 0 0 0;
  margin: 0;
  background-color: $color-background;
  background-image: url('../images/header-image-children.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
}

form {
  input[type=password],
  input[type=text] {
    border: 1px solid $color-font;
  }
}

.header-images-title {
  width: 100%;
  margin: 0 auto 10px auto;
  img {
    margin: 0 auto;
    display: block;
    width: 895px;
  }
  @media(max-width: 950px) {
    img {
      width: 90%;
    }
  }
}

/*#navigagion {
  border: 1px solid red;
}*/

#page-title {
  padding-left: 30px;
  margin-bottom: 0;
  font-weight: normal;
  font-size: 25px;
}

.meta {
  .submitted {
    color: $color-font;
    font-size: 13px;
    margin-bottom: 15px;
  }
}

/*
 * Navigace - vodorovna
 */
#header-region {
  .links {
    //display: flex;
    display: none;
    li {
      list-style: none;
      margin: 0;
      padding: 0;
      flex: 1;
      background-color: #c6e7ec;
      float: left;
      &.active-trail {
        background-color: $color-main-light;
      }
      a {
        color: $color-font;
        display: block;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        align-self: center;
        &.active {
        }
      }
    }
  }
    @media (max-width: 670px) {
      #burger-menu-show-nav2 {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        padding-left: 0;
        li {
        border-bottom: 1px solid $color-main-light;
          a {
            text-align: left;
            font-size: 13px;
            padding: 10px;
            &:hover {
              background-color: #dbf0f5;
            }
          }
        }
      }
    }
}
#block-menu-primary-links {
  width: 100%;
  clear: both;
  float: left;
  .content {
    position: relative;
    .burger-menu {
      display: none;
      border: 2px solid $color-font;
      background-color: $color-main-light;
      height: 30px;
      width: 30px;
      border-radius: 3px;
      margin-left: 93%;
      position: absolute;
      right: 20px;
      top: 5px;
      span {
        border-bottom: 2px solid $color-font;
        width: 80%;
        height: 2px;
        display: block;
        margin: 5px auto 0 auto;
      }
      @media (max-width: 670px) {
        display: block;
      }
    }
  }
  ul.menu {
    height: 70px;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    align-items: stretch;
    background-color: #c6e7ec;
    @media ( max-width: 670px ) {
      display: flex;
      flex-direction: column !important;
      height: 40px;
      padding: 40px 0 0 0;
      &#burger-menu-show {
        height: auto;
        li {
          display: block;
        }
      }
    }
    li {
      list-style: none;
      margin: 0;
      padding: 0;
      flex: 1;
      background-color: #c6e7ec;
      float: left;
      &.active-trail {
        background-color: $color-main-light;
      }
      &:first-child {
        a {
          height: 70px;
          line-height: 70px;
          padding: 0;
        }
      }
      &:nth-child(5) {
        a {
          height: 70px;
          line-height: 70px;
          padding: 0;
        }
      }
      &:nth-child(6) {
        a {
          height: 70px;
          line-height: 70px;
          padding: 0;
        }
      }
      &:last-child {
        a {
          height: 70px;
          line-height: 70px;
          padding: 0;
        }
      }
      a {
        color: $color-font;
        display: block;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        align-self: center;
        height: 100%;
        &.active {
        }
        @media ( max-width: 750px ) {
          padding: 10px;
        }
        @media ( max-width: 670px ) {
          font-size: 13px;
          padding: 13px;
          text-align: left;
        }
      }
      @media ( max-width: 670px ) {
        border-bottom: 1px solid $color-main-light;
        display: none;
      }
    }
  }
}

/*
 * Introductory 
 */
.introductory-block {
  border-bottom: 7px solid $color-background;
  width: 100%;
  background-color: $color-main-light; 
  display: flex;
  justify-content: space-between;

  /*
   * Introductory left
   */
  .introductory-left {
    flex-grow: 2;
    padding-right: 20px;
    //width: 34%;
    width: 30%;

    // Search
    form {
      padding-left: 30px;
      margin: 15px 0;
      label {
        display: none;
      }
      .container-inline {
        display: flex;
        #edit-search-block-form-1-wrapper {
          input {
            margin: 0;
          }
        }
        .form-submit,
        #edit-submit-1,
        #edit-submit-2 {
          order: -1;
          padding: 5px;
          margin-right: 15px;
          background-color: transparent;
          color: $color-font;
          font-weight: normal;
        } 
      }
    }

    // Navigation
    .links {
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      li {
        a {
          display: block;
          padding: 10px 10px 10px 10px;
          border-top: 1px solid $color-main-dark;
          text-decoration: none;
          &:hover {
            background-color: $color-main-dark;
          }
        }
        &:last-child {
          a {
            border-bottom: 1px solid $color-main-dark;
          }
        }
      }
      @media (max-width: 670px) {
        display: none !important;
      }
    }
  }

  /*
   * Introductory right
   */
  .introductory-right {
    padding: 20px;
    height: 330px;
    flex-grow: 7 !important;
    //width: 64%;
    width: 69%;
    min-width: 640px;
    #views_slideshow_singleframe_teaser_section_slideshow-block_1 {
      //width: 100% !important;
      width: 600px;
      float: right;
      margin-right: 17px;
    }
    .views-slideshow-controls-bottom {
      display: none;
    }
  }
}

@media (max-width: 850px) {
  .introductory-block {
    border-bottom: 1px solid $color-main-dark;
    background-color: $color-main-light; 
    .introductory-left {
      width: 100%;
      margin-bottom: 0;
      form {
        display: none;
      }
      .links {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-left: 0;
        li {
          a {
            border: none;
            font-size: 14px;
          }
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            a {
              border: none;
            }
          }
        }
      }
    }
    .introductory-right {
      display: none;
      img {
        width: 100% !important;
        //border: 1px solid red;
      }
    }
  }
}

/*
 * Content
 */
.view-frontpage {
  .views-row {
    height: auto;
    &.views-row-first {
      background-color: $color-main-light;
    }
    .links.inline {
      font-size: 14px;
    }
  }
}

/**
  * Node
  */
.node {
  padding: 30px;
  .content {
    .field-field-picture {
      float: left;
      margin: 0 20px 10px 0;
      position: relative;
      top: 5px;
    }
    .field-field-perex {
      p {
        font-size: 14px;
      }
    }
    .field-field-text {
      p {
        font-size: 14px;
      }
    }
  }
  .links {
    li {
      padding-left: 0;
      font-size: 14px;
    }
  }

  /**
    * Node - full
    */
  &.full {
    padding-top: 0;
    .meta {
      margin-bottom: 30px;
    }
  }

  /**
    * Node - teaser
    */
  &.teaser {
    h2.teaser-title {
      font-size: 19px;
      margin-bottom: 0;
      line-height: 150%;
      a {
        text-decoration: none;
      }
    }
  }
}


/*
* sidebar
*/
#sidebar-left {
  .block {
    padding: 1.5em;
  }
  .block-comment,
  .block-block {
    h2 {
      font-size: em(22);
      margin-bottom: em(5);
    }
  }
  .block .item-list ul {
    padding-left: 0;
    li {
      font-size: 14px;
      color: black;
      //margin-left: 16px;
      a {
        text-decoration: underline;
        color: $color-font;
        font-size: 14px;
      }
    }
  }
  @media (max-width: 720px) {
  }
}

// Prihlaseni
#block-user-0 {
  background-color: $color-main-light;
  form {
    margin-left: 0;
  }
  h2 {
    font-weight: normal;
  }
  .content {
    .form-item {
      margin-bottom: 5px;
      width: 160px;
      label {
        text-align: left;
        margin-bottom: 0;
        font-weight: normal !important;
        font-size: 14px;
      }
    }
    input[type=text] {
    }
    input[type=submit] {
      padding: 0;
      margin: 0;
      background-color: transparent;
      background-image: url('../images/button-login.png');
      float: right;
      width: 41px;
      height: 40px;
      color: transparent;
      position: relative;
      top: -50px;
    }
    .item-list {
      ul {
        margin-left: 0;
        li {
          list-style: none;
          padding-left: 0;
          margin-left: 0;
            text-align: left;
          a {
            text-decoration: none;
            padding: 0;
            margin: 0;
            text-align: left;
          }
        }
      }
    }
  }
}

@media (max-width: 990px) {
//@media (max-width: 720px) {
  #block-user-0 {
    .content {
      input[type=submit] {
        float: left;
        width: 100%;
        //width: 41px;
        background-repeat: no-repeat;
            float: left;
            position: static;
            top: 0;
            margin-right: 25px;
            margin-bottom: 25px;
      }
    }
  }
}

/*
 * Registrace / Prihlaseni
 */
#block-user-1 {
  background-color: $color-main-light; 
  margin-bottom: 30px;
  & > h2 {
    display: none;
  }
  &.block {
    ul {
      margin-left: 0;
    }
  }
  .menu {
    li {
      list-style: none;
      a {
        display: block;
        padding: 10px ;
        border-bottom: 1px solid #c1e1e6;
      }
    }
  }
}

/*
 * Adresa
 */
#block-block-1 {
  &.block {
    padding-bottom: 0;
  }
  p {
    color: $color-font;
    font-size: 14px;
    line-height: 180%;
    strong {
      u {
        text-decoration: none;
      }
    }
  }
}

#block-block-6 {
  .content {
    img {
      margin-bottom: 30px;
    }
  }
}

/*
* Footer
*/
#footer-wrapper {
  @include outer-container;
  margin-bottom: 200px;
}

@media (max-width: 720px) {
  #container {
    display: flex;
    flex-direction: column;
  }
  #main {
    order: 1;
    width: 100%;
    //flex: 1;
  }
  #sidebar-left {
    margin-top: 100px;
    width: 100%;
    order: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .block {
      width: 45%;
    }
    #block-block-1,
    #block-block-6,
    #block-comment-0,
    #block-user-0 {
    }

    // prihlaseni
    #block-user-0 {
      background-color: transparent;
      order: 1;
      .content {
        input[type=submit] {
          float: left;
          position: static;
          top: 0;
          margin-right: 25px;
        }
      }
    }
    // adresa
    #block-block-1 {
      order: 2;
    }
    // comment
    #block-comment-0 {
      order: 3;
    }
    // bannery
    #block-block-6 {
      order: 4;
      flex-basis: 100%;
      .content {
        display: flex;
        align-items: flex-start;
        a {
          justify-align: space-between;
        }
      }
    }
  }
}
@media (max-width: 560px) {
  #sidebar-left {
    .block {
      width: 95%;
    }
  }
}



/*
* Kontakt
*/
#node-48 {
  .content {
    h3 {
      font-size: 20px;
      font-weight: normal;
    }
    div {
      margin-bottom: 40px;
      width: 47%;
      float: left;
      p {
        margin-bottom: 2px;
        font-size: 15px;
      }
      @media (max-width: 850px) {
        width: 95%;
      }
    }
  }
}

/*
* Kalendar
*/
.page-calendar {
  #content-content {
    padding: 30px;
    .view-content {
      .links {
        width: 95%;
        clear: both; 
        position: static;
        float: left;
        margin-bottom: 30px;
        font-size: 15px;
        li {
          padding-left: 0;
          margin-left: 0;
        }
      }
    }
  }
}

/*
* Zakazky
*/
.page-vz-zakazky {
  form {
    margin-left: 0;
    margin-right: 0;
  }
  .view-display-id-attachment_1 {
    padding: 0;
    table {
      margin-top: 50px;
      margin-bottom: 50px;
      border-collapse: separate;
      border-spacing: 1px;
      background-color: $color-main-light;
      thead {
            //background-color: $color-background;
        tr {
          th {
            background-color: $color-main-light;
            padding: 10px;
            border-bottom: none;
            color: $color-font;
          }
        }
      }
      tr {
          background-color: transparent !important;
        td {
          padding: 10px;
          font-size: 14px;
          background-color: $color-background;
          border-bottom: none;
        }
      }
    }
  }
}

/*
* Fotogalerie
*/
.page-fotogalerie {
  #content {
    .view-gallery-directories {
      .views-row {
        width: 47%;
        float: left;
        .node {
          padding-top: 0;
          padding-bottom: 0;
        }
        @media (max-width: 550px) {
          width: 95%;
        }
      }
    }
  }
}
