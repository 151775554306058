//////////////////////////////////////////////////
// Custom MIXINS and FUNCTIONS
/////////////////////////////////////////////////

// from http://www.joshfry.me/blog/2013/05/13/omega-reset-for-bourbon-neat
@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}

// http://nicolasgallagher.com/another-css-image-replacement-technique/
@mixin hidden-text() {
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}

// backported from bourbon 4x
// Convert pixels to ems
// eg. for a relational value of 12px write em(12) when the parent is 16px
// if the parent is another value say 24px write em(12, 24)

@function em($pxval, $base: 16) {
  @if not unitless($pxval) {
    $pxval: strip-units($pxval);
  }
  @if not unitless($base) {
    $base: strip-units($base);
  }

  @return ($pxval / $base) * 1em;
}


//////////////////////////////////////////////////
// EXTENSIONS as a reusable components with placeholder % instead of @extend
// http://sass-lang.com/documentation/file.SASS_REFERENCE.html#placeholders
/////////////////////////////////////////////////

@mixin language-switcher() {
  li {
    a {
      font-weight: 500;
      font-size: em(15);
      color: #010101;
      text-transform: uppercase;
      &:hover {
        color: black;
      }
    }
    display: block;
    &.active {
      display: none;
    }
  }
}

@mixin main-menu() {
  #main-menu {
    ul {
      li {
        list-style: none;
        a {
          display: inline-block;
          padding: 5px 10px;
          text-transform: uppercase;
          color: $hp-navigace;
          font-size: em(14);
          font-family: $sebek-font;
          font-weight: 400;
          padding: 0 10px;
          height: 35px;
          line-height: 35px;
          transition: 300ms;
          &:hover {
            color: white;
            font-size: 23px;
          }
        }
      }
    }
  }
}

@keyframes homepage_rl {
    from {
      left: 1500px;
    }
    to {
      left: 0px;
    }
}

@keyframes homepage_lr {
    from {
      left: 1500px;
      //right: -1500px;
    }
    to {
      left: 0px;
    }
}

@keyframes homepage_mouse {
    from {
      background-position: 50% 50%;
    }
    to {
      background-position: 50% 55%;
    }
}
